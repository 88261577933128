import Link from 'next/link';
import React from 'react';
import Button, { ButtonSize, ButtonType } from './buttons/Button';

const ViewDealsButton = () => {
  return (
    <Link
      className="flex items-center py-2"
      href={'/deals'}
      passHref
      legacyBehavior
    >
      <Button
        classes="my-6"
        size={ButtonSize.primary}
        type={ButtonType.primary}
      >
        Explore Opportunities
      </Button>
    </Link>
  );
};
export default ViewDealsButton;
