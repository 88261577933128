/* eslint-disable */

import SeoHead from '@/components/head/SeoHead';
import CardTrio from '@/components/sections/landing/CardTrio';
import HeroVideo from '@/components/sections/landing/HeroVideo';
import HowItWorks from '@/components/sections/landing/HowItWorks';
import LandingSection from '@/components/sections/landing/LandingSection';
import PreFooter from '@/components/sections/landing/PreFooter';
import styles from '@/styles/Home.module.scss';

import type { NextPage } from 'next';
import {usePageEntrance} from "@/hooks/usePageEntrance";

const Home: NextPage = () => {
  usePageEntrance()
  return (
    <>
      <SeoHead
        title={'OverpassIP'}
        imageURI={'/img/OverPassCover.png'}
        description={
          'Imagine your NFT as toys, GIFs, collectibles, and more. With OverpassIP, you can maximize the potential of your NFTs. Access opportunities to license them to Web2 brands, earn ongoing royalties, and join us in revolutionizing IP monetization.'
        }
      />
      <main className={styles.main}>
        <LandingSection />
        <HeroVideo />
        <HowItWorks />
        <CardTrio />
        <PreFooter />
      </main>
    </>
  );
};

export default Home;
