/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import React from 'react';
import style from '@/styles/Home.module.scss';
import styles from '@/styles/Home.module.scss';

const HowItWorks = () => {
  return (
    <div
      className={`${styles.cardtrio} container grid max-w-[983px] grid-cols-1 items-center gap-4 md:grid-cols-2 md:px-4`}
    >
      <div className={style.cardblur} />
      <div className="md:col-span-2">
        <div className={`${style.largecard} ${style.landingcard}`}>
          <div className={`${style.cardbg} desktop-only`}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Image
              width={1300}
              height={614}
              quality={100}
              layout={'responsive'}
              src={'/img/home/large-card-bg.jpg'}
            />
          </div>
          <div className={`${style.cardbg} mobile-only`}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Image
              width={522}
              height={561}
              quality={100}
              layout={'responsive'}
              src={'/img/home/small-card-bg.jpg'}
            />
          </div>
          <div className={`${style.cardcontent} flex flex-col items-center`}>
            <h4 className="font-gt-haptik text-heading-4 font-bold md:text-heading-3 lg:text-heading-2">
              Holders
            </h4>
            <p className="max-w-[580px] text-center font-satoshi-med font-medium">
              Maximize your NFT&apos;s potential with brand licensing. Gain
              recognition and influence by licensing your NFT to renowned
              brands.
            </p>
            <div>
              <img
                alt="Three collectible digital characters displayed side by side: a penguin in green attire on red, a penguin with a brown hat on blue, and a yellow ape with a hat on purple."
                src="/img/home/holders.png"
                className={'ml-[35px] mt-[-20px] md:mt-[-40px]'}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={`${style.smallcard} ${style.landingcard}`}>
          <div className={`${style.cardbg}`}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Image
              width={522}
              height={561}
              quality={100}
              layout={'responsive'}
              src={'/img/home/small-card-bg.jpg'}
            />
          </div>
          <div className={`${style.cardcontent} flex flex-col items-center`}>
            <h4 className="font-gt-haptik text-heading-4 font-bold md:text-heading-3 lg:text-heading-2">
              Collections
            </h4>
            <p className="max-w-[367px] text-center font-satoshi-med font-medium">
              Each licensed NFT amplifies your collection&apos;s reach,
              providing you with more exposure and uniqueness to unlock
              unparalleled growth for your project.
            </p>
            <div>
              <img
                alt="An illustrated penguin adorned with a floral lei framed in a purple, postage stamp-like border, complemented by a rising arrow and a checkmark badge on a dark purple background."
                src="/img/home/collections.png"
                className={'ml-[-32px] mt-[15px]'}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={`${style.smallcard} ${style.landingcard}`}>
          <div className={`${style.cardbg}`}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Image
              width={522}
              height={561}
              quality={100}
              layout={'responsive'}
              src={'/img/home/small-card-bg.jpg'}
            />
          </div>
          <div className={`${style.cardcontent} flex flex-col items-center`}>
            <h4 className="font-gt-haptik text-heading-4 font-bold md:text-heading-3 lg:text-heading-2">
              Brands
            </h4>
            <p className="max-w-[367px] text-center font-satoshi-med font-medium">
              Seamlessly partner with Web3 collections and acquire the perfect
              IP that aligns with your campaigns.
            </p>
            <div>
              <img
                alt="Digital icons of popular NFT collections displayed above a glowing hexagonal platform: a Pudgy Penguin in a blue circle, a Pudgy Igloo in a purple circle, and the Bored Ape Yacht Club (BAYC) logo in a pink circle, set against a dark backdrop with a spotlight effect."
                src="/img/home/brands.png"
                className={'mt-[35px] md:mt-[15px]'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
