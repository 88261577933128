import Image from 'next/image';
import React from 'react';
import ViewDealsButton from '@/components/UI/ViewDealsButton';
import styles from '@/styles/Home.module.scss';
import prefooterGrid from '~/img/home/prefooter-grid.png';

const PreFooter = () => {
  return (
    <div
      className={`${styles.prefooter} flex flex-col items-center bg-cover bg-center`}
    >
      <div className={`${styles.prefooterGridWrapper}`}>
        <Image src={prefooterGrid} alt="" />
        <div className={`${styles.prefooterGradient}`} />
      </div>
      <h4 className="font-gt-haptik text-heading-5 font-bold text-accent-main md:text-heading-3 lg:text-heading-2">
        Explore Opportunities
      </h4>
      <p className="max-w-[414px] text-center text-lg">
        Make NFT’s non-fungible again with OverpassIP.
      </p>
      <div className={`${styles.buttonduo} flex flex-row`}>
        <ViewDealsButton />
      </div>
      <div className={`${styles.cardmerch} flex flex-row justify-between`}>
        <div className={`${styles.assetcard}`}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            layout={'fill'}
            objectFit={'contain'}
            quality={100}
            src={'/img/home/landingCards/prefooter-card-1.png'}
          />
        </div>

        <div className={`${styles.assetcard} ${styles.assetcardtwo}`}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            layout={'fill'}
            objectFit={'contain'}
            quality={100}
            src={'/img/home/landingCards/prefooter-card-2.png'}
          />
        </div>

        <div className={`${styles.assetcard}`}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            layout={'fill'}
            objectFit={'contain'}
            quality={100}
            src={'/img/home/landingCards/prefooter-card-3.png'}
          />
        </div>

        <div className={`${styles.assetmerch} ${styles.assetmerchone}`}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            className={'merch merch-1'}
            layout={'fill'}
            objectFit={'contain'}
            quality={100}
            src={'/img/home/landingCards/prefooter-merch-1.png'}
          />
        </div>

        <div className={`${styles.assetmerch} ${styles.assetmerchtwo}`}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            className={'merch merch-2'}
            layout={'fill'}
            objectFit={'contain'}
            quality={100}
            src={'/img/home/landingCards/prefooter-merch-2.png'}
          />
        </div>
      </div>
    </div>
  );
};
export default PreFooter;
