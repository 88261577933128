/* eslint-disable */
import gsap from "gsap";
import Image from "next/image";
import React from "react";
import style from "@/styles/Home.module.scss";
import { CLOUDFLARE_BUCKET_URL } from "@/constants/constants";

const HeroVideo = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  function playVid(event: any) {
    const playButton = document.getElementById('play-button');
    const playButtonGradient = playButton?.parentElement;
    const videoCover = playButtonGradient?.previousElementSibling;
    const video =
      playButtonGradient?.previousElementSibling?.previousElementSibling;
    gsap.to([playButton, playButtonGradient, videoCover], {
      autoAlpha: 0,
      ease: 'sine.inOut',
      duration: 0.23,
    });
    // @ts-ignore
    video.play();
    // @ts-ignore
    video.addEventListener('click', () => {
      // @ts-ignore
      if (video.paused) {
        // @ts-ignore
        video.play();
      } else {
        // @ts-ignore
        video.pause();
      }
    });
  }

  return (
    <div className={`${style.herovideo} ${style.c164} max-w-[100vw]`}>
      <div className={`${style.videowrapper} enter-el`}>
        <div className={style.bgwrapper}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            width={1800}
            height={1209}
            quality={100}
            className={style.bg}
            priority={true}
            layout={'responsive'}
            src={'/img/home/vid-bg.jpg'}
          />
        </div>
        <div className={`${style.framewrapper}`}>
          <div className={style.gradone} />
          <div className={style.gradtwo} />
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            quality={100}
            className={style.frame}
            layout={'fill'}
            src={'/img/home/vid-frame.png'}
          />
        </div>
        <div className={`${style.coverwrapper}`}>
          <video preload="true" loop playsInline>
            <source src={`${CLOUDFLARE_BUCKET_URL}/overpass.mp4`} />
          </video>
          <Image
            quality={100}
            className={style.cover}
            layout={'fill'}
            src={'/img/home/vid-cover.png'}
          />
          <div className={style.playcover} id="play-gradient">
            <button
              className={style.playbutton}
              id="play-button"
              onClick={playVid}
            >
              <svg
                width="123"
                height="123"
                viewBox="0 0 123 123"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_b_3855_5426)">
                  <circle
                    cx="61.5"
                    cy="61.5"
                    r="61.5"
                    fill="white"
                    fillOpacity="0.05"
                  />
                  <circle
                    cx="61.5"
                    cy="61.5"
                    r="61"
                    stroke="white"
                    strokeOpacity="0.08"
                  />
                </g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M75.8937 61.4834L53.2129 48.8516V74.1494L75.8937 61.4834Z"
                  fill="#9DB4F0"
                />
                <defs>
                  <filter
                    id="filter0_b_3855_5426"
                    x="-32"
                    y="-32"
                    width="187"
                    height="187"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="16" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_3855_5426"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_3855_5426"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <span className={`${style.dottedline}`} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroVideo;
