import Image from 'next/image';
import React from 'react';
import style from '@/styles/Home.module.scss';

const HowItWorks = () => {
  return (
    <div
      className={`${style.howitworks} flex max-w-[100vw] justify-center`}
      data-entrance="stagger-fade"
      data-offset=".75"
      data-mobile-offset=".85"
    >
      <div className="container flex w-full flex-col">
        <h3 className="mb-5 text-center font-gt-haptik text-heading-4 font-bold md:text-heading-3 lg:text-heading-2">
          How It Works
        </h3>
        <div
          className={
            'flex w-full flex-col justify-between md:flex-row md:gap-20 md:px-4'
          }
        >
          <div className="mb-14 flex flex-col items-center justify-center text-center md:mb-0">
            <div className={`${style.howicon}`}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <Image
                width={254}
                height={209}
                quality={100}
                layout={'responsive'}
                src={'/img/home/icon-how.png'}
              />
            </div>
            <p className="font-satoshi-bold text-heading-6 font-bold">
              Holders
            </p>
            <p className="font-satoshi-reg text-md">
              Holders can submit their NFTs with OverpassIP to be considered for
              licensing opportunities given by collections or brands.
            </p>
          </div>
          <div className="mb-14 flex flex-col items-center justify-center text-center md:mb-0">
            <div className={`${style.howicon}`}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <Image
                width={254}
                height={209}
                quality={100}
                layout={'responsive'}
                src={'/img/home/icon-how.png'}
              />
            </div>
            <p className="font-satoshi-bold text-heading-6 font-bold">
              Collections
            </p>
            <p className="font-satoshi-reg text-md">
              Collections being on OverpassIP allows endless opportunities for
              their brand to grow while bringing their holders with them, all
              through licensing.
            </p>
          </div>
          <div className="mb-14 flex flex-col items-center justify-center text-center md:mb-0">
            <div className={`${style.howicon}`}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <Image
                width={254}
                height={209}
                quality={100}
                layout={'responsive'}
                src={'/img/home/icon-how.png'}
              />
            </div>
            <p className="font-satoshi-bold text-heading-6 font-bold">Brands</p>
            <p className="font-satoshi-reg text-md">
              Brands can post licensing opportunities for collections, collect a
              pool of NFTs to choose from, and pick the IP that will best
              represent their initiative with the OverpassIP platform.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
