import React from 'react';
import ViewDealsButton from '@/components/UI/ViewDealsButton';
import style from '@/styles/Home.module.scss';

const LandingSection = () => {
  return (
    <>
      <div
        className={`${style.landingsection} flex max-w-xl flex-col items-center text-center`}
      >
        <h1
          className={`${style.heroHeader} mb-2 font-gt-haptik font-bold md:text-heading-1`}
        >
          <span className="enter-el font-gt-haptik text-heading-4 font-bold md:text-heading-2 lg:text-heading-1">
            NFT Licensing
          </span>
          <span className="enter-el block font-gt-haptik text-heading-4 font-bold md:text-heading-2 lg:text-heading-1">
            Made Easy.
          </span>
        </h1>
        <p className="enter-el mb-8 mt-4 text-lg font-medium md:text-heading-6">
          Maximize the potential of your NFT’s
          <br />
          with OverpassIP.
        </p>
        <span className="enter-el">
          <ViewDealsButton />
        </span>
      </div>
    </>
  );
};
export default LandingSection;
