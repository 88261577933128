import Head from 'next/head';
import React from 'react';

interface SeoHeadProps {
  title: string;
  description?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  imageURI?: string;
  pagePath?: string;
}

const SeoHead = ({
  title,
  description,
  imageURI,
  pagePath,
  twitterTitle,
  twitterDescription,
}: SeoHeadProps) => {
  const defaultDescription =
    description ??
    'Most people don’t have the resources, time, or connections to truly use their web3 IP. Our goal at OverpassIP is to provide holders with easily accessible opportunities to leverage their NFTs IP. With OverpassIP, the web3 user will seamlessly connect with the Web2 brand. Join us in revolutionizing IP functionality for years to come.';
  return (
    <Head>
      <title>{title}</title>
      <link rel="shortcut icon" type="image/svg+xml" href="/overpass.svg" />
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={defaultDescription} />
      <meta
        property="og:image"
        content={imageURI ? imageURI : '/img/OverPassCover.png'}
        key="ogimage"
      />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:description"
        content={defaultDescription}
        key="ogdesc"
      />
      <meta property="og:url" content={pagePath ?? '/'} key="ogurl" />
      <meta name="twitter:site" content="@OverpassIP" />
      <meta name="twitter:creator" content="@OverpassIP" />
      <meta
        name="twitter:title"
        content={twitterTitle ? twitterTitle : title}
      />
      <meta
        name="twitter:description"
        content={twitterDescription ? twitterDescription : defaultDescription}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content={imageURI ? imageURI : '/img/OverPassCover.png'}
      />
    </Head>
  );
};
export default SeoHead;
